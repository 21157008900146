<template>
  <div class="expense-list-container">
    <my-nav-bar
      title="我的报销"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="expense-list-content">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getExpenses"
        >
          <div v-for="(expense, index) in expenses" :key="index" class="expense-list-cell">
            <van-cell title="报销类型" :value="expense.type | typeFilter" />
            <van-cell title="报销状态" :value="expense.status | statusFilter" />
            <van-cell class="expense-list-cell__amount" title="报销金额" :value="'￥'+expense.amount" />
            <van-cell title="报销内容" :label="expense.description" />
            <van-cell>
              <van-grid class="expense-list-cell__image" :column-num="3" square :border="false" :gutter="10">
                <van-grid-item v-for="(image, i) in expense.images" :key="i">
                  <van-image width="2rem" height="2.1rem" :src="image" fit="cover" />
                </van-grid-item>
              </van-grid>
            </van-cell>
            <van-button v-if="!expense.bill_id" type="danger" text="撤销" square block @click="handleDelete(expense, index)" />
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import expenseApi from '@/api/expense'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ExpenseList',
  components: { myNavBar },
  filters: {
    typeFilter(type) {
      const typeMap = {
        1: '餐费',
        2: '其它'
      }
      return typeMap[type]
    },
    statusFilter(status) {
      const statusMap = {
        0: '待审核',
        1: '已完成'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      expenses: [],
      page: 0,
      limit: 10,
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getExpenses() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit
      }
      expenseApi.list(params).then(res => {
        this.expenses = this.expenses.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
        this.showEmpty = this.expenses.length === 0
      })
    },
    handleDelete(expense, index) {
      this.confirm('确定要撤销此次费用报销吗？').then(() => {
        const data = {
          expense_id: expense.id
        }
        expenseApi.delete(data).then(res => {
          this.success(res.msg)
          this.expenses.splice(index, 1)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .expense-list-content {
    padding: 15px 15px 5px 15px;
  }
  .expense-list-cell {
    margin-bottom: 10px;
    &__amount {
      .van-cell {
        &__value {
          color: #ee0a24;
        }
      }
    }
    &__image {
      .van-grid-item {
        &__content {
          background-color: #F7F7F7;
          border-radius: 4px;
        }
      }
    }
  }
</style>
