import request from '@/utils/request'
import FormData from 'form-data'

export default {

  upload(file) {
    const form = new FormData()
    form.append('image', file)
    return request({
      method: 'post',
      url: 'expense/upload',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  add(data) {
    return request({
      method: 'post',
      url: 'expense',
      data: data
    })
  },
  list(params) {
    return request({
      method: 'get',
      url: 'expense',
      data: params
    })
  },
  delete(data) {
    return request({
      method: 'delete',
      url: `expense/${data.expense_id}`
    })
  }

}
