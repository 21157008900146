var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "expense-list-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "我的报销",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "expense-list-content"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getExpenses
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.expenses, function (expense, index) {
    return _c('div', {
      key: index,
      staticClass: "expense-list-cell"
    }, [_c('van-cell', {
      attrs: {
        "title": "报销类型",
        "value": _vm._f("typeFilter")(expense.type)
      }
    }), _c('van-cell', {
      attrs: {
        "title": "报销状态",
        "value": _vm._f("statusFilter")(expense.status)
      }
    }), _c('van-cell', {
      staticClass: "expense-list-cell__amount",
      attrs: {
        "title": "报销金额",
        "value": '￥' + expense.amount
      }
    }), _c('van-cell', {
      attrs: {
        "title": "报销内容",
        "label": expense.description
      }
    }), _c('van-cell', [_c('van-grid', {
      staticClass: "expense-list-cell__image",
      attrs: {
        "column-num": 3,
        "square": "",
        "border": false,
        "gutter": 10
      }
    }, _vm._l(expense.images, function (image, i) {
      return _c('van-grid-item', {
        key: i
      }, [_c('van-image', {
        attrs: {
          "width": "2rem",
          "height": "2.1rem",
          "src": image,
          "fit": "cover"
        }
      })], 1);
    }), 1)], 1), !expense.bill_id ? _c('van-button', {
      attrs: {
        "type": "danger",
        "text": "撤销",
        "square": "",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleDelete(expense, index);
        }
      }
    }) : _vm._e()], 1);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }